import React, { Fragment, memo, useCallback } from 'react';
import cx from 'classnames';
import InformationModule from '@components/InformationModule';
import ReactTabsWarpper from '@components/ReactTabsWarpper';
import Accordion from '@components/accordion';
import { default as CarouselComponent } from '@components/carouselWrapper';
import contentTypeRenderer from '@components/contentTypeRenderer';
import { default as FormComponent } from '@components/form';
import HeadingWithSubText from '@components/headingWithSubText';
import HeroBanner from '@components/heroBanner';
import RichText from '@components/richtext';
import Row from '@components/row';
import SideBySideContent from '@components/sideBySideContent';
import VideoGallery from '@components/videoGallery';
import { getBgColorClasses, getTextColorClasses } from '@utils/index';
import { PageContentTypes, RIGHT } from '@constants/index';

/**
 * @class
 * @param {Object} sectionData Fields Data for section elements
 * @param {Object} sectionType type of Section
 * @param {string} sectionBackgroundColor Background color for Section.
 *
 * @returns Section Component as per the section data
 */

const Section = ({
  sectionData,
  selectedItem,
  id,
  onClick,
  sectionType,
  position,
  sectionBackgroundColor
}) => {
  const {
    sectionId,
    informationModuleRows,
    cardRows,
    heading,
    headingTextColor,
    paragraphText
  } = sectionData || {};
  const {
    HeadingwithSubText,
    CardRows,
    InformationModuleSection,
    SideBySide,
    Carousel,
    HeroSection,
    Form,
    AccordionContent,
    VideoGalleryId
  } = PageContentTypes;
  const priority = position < 2;
  const sectionClasses = cx(
    'align-middle',
    'border-b-0',
    {
      'text-white': sectionBackgroundColor === 'Black'
    },
    {
      'text-black': sectionBackgroundColor === 'White'
    },
    getBgColorClasses(sectionBackgroundColor),
    {
      'lg:px-10': informationModuleRows?.length
    }
  );
  const SectionDefaultUI = useCallback(
    () => (
      <section
        className={`${sectionClasses} ${!sectionData?.heading ? 'pt-20' : ''}`}
        data-testid={sectionId}
        id={sectionId || ''}
      >
        {sectionData?.heading && (
          <h2
            className={cx(
              'text-5xl font-normal pt-11 pb-5 mx-10',
              getTextColorClasses(headingTextColor)
            )}
          >
            {heading}
          </h2>
        )}
        {paragraphText && (
          <div className='mx-10'>
            <RichText
              text={paragraphText}
              classNames={{ paragraphCustomClasses: 'mt-0' }}
            />
          </div>
        )}
        {cardRows?.map(cardRow => (
          <Row key={cardRow?.sys?.id} fields={cardRow?.fields} />
        ))}
      </section>
    ),
    [
      cardRows,
      heading,
      headingTextColor,
      informationModuleRows,
      sectionClasses,
      sectionData?.heading,
      sectionId
    ]
  );

  // render section specific UI

  const renderSection = useCallback(
    sectionType => {
      {
        switch (sectionType) {
          case HeadingwithSubText:
            // render page title
            return (
              <HeadingWithSubText
                fields={sectionData}
                position={position}
                individualSection={true}
              />
            );
          case SideBySide:
            return (
              <SideBySideContent
                sectionData={sectionData}
                sectionBackgroundColor={sectionBackgroundColor}
                priority={priority}
              />
            );
          case HeroSection:
            return (
              <HeroBanner
                sectionData={sectionData}
                priority={priority}
              ></HeroBanner>
            );
          case InformationModuleSection:
            return (
              <div className='px-1 py-1 text-helveticaNeueLTBd'>
                <h3 className='font-bold text-primary'>
                  {sectionData?.heading}
                </h3>
                <div
                  className='w-full h-5 bg-[#f1f1f1]'
                  style={{ borderBottom: '1px solid #808080' }}
                ></div>
                {sectionData?.informationModuleRows?.map((i, index) => (
                  <InformationModule
                    key={i?.sys.id}
                    data={i?.fields.informationModules}
                    header={i?.fields.internalTitle}
                  />
                ))}
              </div>
            );
          case CardRows:
            return <SectionDefaultUI />;
          case Carousel:
            return (
              <CarouselComponent
                carouselItems={sectionData?.carouselItem}
                priority={priority}
              />
            );
          case Form:
            return (
              <div className='m-auto px-10'>
                <FormComponent formData={sectionData} />
              </div>
            );
          case AccordionContent:
            return (
              <Accordion
                classNames={{
                  container:
                    '!bg-transparent font-Roman flex flex-col border-b-2 lg:w-4/6 w-5/6 !mx-auto mb-5 justify-center',
                  parent: 'pb-2 mb-2 w-full',
                  content: 'pt-3 m-auto !px-0',
                  header: 'px-3 text-lg !font-medium'
                }}
                plusMinusIcons={true}
                isOpen={selectedItem === id}
                accordionId={id}
                onClick={onClick}
                header={sectionData?.title}
              >
                {sectionData?.accordionContent?.map(contentItem => (
                  <Fragment key={contentItem?.sys?.contentType?.sys?.id}>
                    {contentTypeRenderer(
                      contentItem?.sys?.contentType?.sys?.id,
                      contentItem?.fields,
                      RIGHT,
                      priority,
                      {
                        parent: '!pt-0',
                        child: 'pt-0 px-0 mt-0 lg:mt-8 lg:mt-5 lg:px-12'
                      }
                    )}
                  </Fragment>
                ))}
              </Accordion>
            );

          case VideoGalleryId:
            return <VideoGallery sectionData={sectionData} />;
          case 'tabsSection':
            return <ReactTabsWarpper tabs={sectionData?.tabs} />;
          default:
            return null;
        }
      }
    },
    [
      AccordionContent,
      CardRows,
      Carousel,
      Form,
      HeadingwithSubText,
      HeroSection,
      InformationModuleSection,
      SideBySide,
      id,
      onClick,
      position,
      priority,
      sectionBackgroundColor,
      sectionData,
      selectedItem,
      VideoGalleryId
    ]
  );

  // render multiple type of sections
  return (
    <div
      className={`${
        position === 0 && (sectionType === HeroSection ? '' : 'mt-[100px]')
      }`}
    >
      {renderSection(sectionType)}
    </div>
  );
};

export default memo(Section);
